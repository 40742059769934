import gsap from 'gsap';
import Config from './config.js';
import AnalyticsManager from './analytics-manager.js';
import FormManager from './form-manager.js';
import Utils from './utils.js';
// import 'regenerator-runtime/runtime';

const cameraHudComponent = {
  init() {
    window.cameraHudComponent = this;
    this.cam = document.getElementById('camera').components.camera.el.object3D.children[0];

    this.container = document.getElementById('container');

    this.welcomeScreen = document.getElementById('welcomeScreen');
    this.welcomeScreenBtn = document.getElementById('welcomeScreenBtn');
    this.welcomeScreenBtn.onclick = () => {
      window.gameManager.welcomeScreenBtnClicked();
      window.splashComponentRef.playButtonSound();
    };

    this.trackingPopupContainer = document.getElementById('trackingPopupContainer');
    this.trackingImageProgress = document.getElementById('tracking-image-progress');
    this.placeSceneContainer = document.getElementById('placeSceneContainer');
    this.placeSceneBtn = document.getElementById('placeSceneBtn');
    this.placeSceneBtn.onclick = () => window.gameManager.placeScene();

    this.trackingText = document.getElementById('tracking-text');
    this.activateWithoutCard = document.getElementById('activate-without-card');
    this.activateWithoutCard.onclick = () => this.activateWithoutCardClicked();

    this.topHeader = document.querySelector('.top-header');
    this.topLogo = document.querySelector('.top-logo');

    this.lowerDecoration = document.querySelector('.lower-decoration');

    this.utilButtons = document.getElementById('util-buttons');
    // this.refreshExperienceBtn = document.getElementById('refreshExperienceBtn');
    // this.refreshExperienceBtn.onclick = () => this.refreshExperienceBtnPressed();
    this.contactBtn = document.getElementById('contactBtn');
    this.contactBtn.onclick = () => this.contactBtnPressed();

    this.poiInfoContainer = document.getElementById('poi-info-container');
    this.poiInfoContent = document.getElementById('poi-info-content');
    // this.poiInfoIcon = document.getElementById('poi-info-icon');
    // this.poiInfoNumber = document.getElementById('poi-info-number');
    // this.poiInfoTitle = document.getElementById('poi-info-title');
    this.poiInfoBigHeader = document.getElementById('poi-info-big-header');
    this.poiInfoRoomIcon = document.getElementById('poi-info-room-icon');
    this.poiInfoMainImage = document.getElementById('poi-info-main-image');
    this.poiInfoMainImageCaption = document.getElementById('poi-info-main-image-caption');
    this.poiInfoMainText = document.getElementById('poi-info-main-text');
    this.poiInfoBodyText = document.getElementById('poi-info-body-text');
    this.poiInfoSection2SmallText = document.getElementById('poi-info-section-2-small-text');
    this.poiInfoSection2Title = document.getElementById('poi-info-section-2-title');
    this.poiInfoSectionDescriptionText = document.getElementById('poi-info-description-text');

    // this.poiInfoGalleryImages = document.getElementById('poi-info-gallery-images');
    // this.poiInfoGalleryImageTemplate = document.getElementById('poi-info-gallery-image-template');
    // this.poiInfoBulletPointsUl = document.getElementById('poi-info-bullet-points-ul');
    this.poiInfoCloseBtn = document.getElementById('poi-info-close-btn');
    this.poiInfoCloseBtn.onclick = () => this.poiInfoCloseBtnPressed();
    this.poiInfoTopClose = document.getElementById('poi-info-top-close');
    this.poiInfoTopClose.onclick = () => this.poiInfoCloseBtnPressed();
    this.poiInfoSeeProjectBtn = document.getElementById('poi-info-see-project-btn');
    this.poiInfoSeeProjectBtn.onclick = () => this.moreInfoBtnPressed();
    this.poiInfoContactBtn = document.getElementById('poi-info-contact-btn');
    this.poiInfoContactBtn.onclick = () => this.contactBtnPressed();

    this.formBox = document.getElementById('formBox');
    this.formCloseBtn = document.getElementById('form-close-btn');
    this.formCloseBtn.onclick = () => this.showHideForm(false);

    this.showHideTrackingPopupContainer(false, true);
    this.showHidePlaceSceneContainer(false, true);
    this.showHideUtilButtons(false);

    // Prevent interaction with all images
    document.querySelectorAll('img').forEach((img) => {
      // console.log('document.querySelectorAll - img:', img);
      img.setAttribute('draggable', false);
      img.setAttribute('oncontextmenu', 'return false;');
    });

    FormManager.initForm();
  },

  restartScene() {
    if (window.gameManager.restartingScene) return;
    window.console.log('camera-hud restartScene()');
    window.gameManager.restartScene();
  },

  showHideWelcomeScreen(newValue = true, instant = false) {
    if (newValue) {
      this.welcomeScreen.style.display = null;
      this.welcomeScreen.classList.remove('hideAlphaDown');
    }
    else this.welcomeScreen.classList.add('hideAlphaDown');
  },

  showHideTrackingPopupContainer(newValue = true, instant = false) {
    console.log('showHideTrackingPopupContainer - newValue:', newValue);
    if (newValue) {
      // this.trackingPopupContainer.style.display = null;
      this.trackingPopupContainer.classList.remove('hideAlphaDown');
      this.lowerDecoration.style.opacity = 0;
    }
    else {
      this.trackingPopupContainer.classList.add('hideAlphaDown');
      this.lowerDecoration.style.opacity = null;
      this.stopTrackingPopupAutocloseProgress();
    }
  },

  startTrackingPopupAutocloseProgress(tweenDuration = 5, callback = null) {
    this.trackingImageProgress.classList.remove('hideAlpha');
    let maskValue;
    const twnObj = { value: 0 };
    this.trackingImageProgressTwn = gsap.fromTo(twnObj,
      {
        value: 0,
      },
      {
        duration: tweenDuration,
        value: 360,
        ease: 'none',
        onUpdate: () => {
          if (this.trackingImageProgressTwn) {
            maskValue = `conic-gradient(from 0deg, rgb(166 0 255), #00fff9 ${360 * this.trackingImageProgressTwn.ratio}deg, #00000000 0deg)`;
            this.trackingImageProgress.style.setProperty('-webkit-mask-image', maskValue);
            this.trackingImageProgress.style.setProperty('mask-image', maskValue);
          }
        },
        onComplete: () => {
          if (callback && typeof callback === 'function') callback.call();
        },
        callbackScope: this,
      },
    );
  },

  stopTrackingPopupAutocloseProgress() {
    if (this.trackingImageProgressTwn) this.trackingImageProgressTwn.kill();
    this.trackingImageProgress.classList.add('hideAlpha');
  },

  activateWithoutCardClicked() {
    if (this.withoutCard) return;
    this.withoutCard = true;
    this.trackingText.innerHTML = 'Tap to place the scene.';
    this.activateWithoutCard.style.display = 'none';
    window.gameManager.startWithoutCardMode();
    window.splashComponentRef.playButtonSound();
    this.stopTrackingPopupAutocloseProgress();
    AnalyticsManager.sendActivateARNoCardEvent();
  },

  showHidePlaceSceneContainer(newValue = true, instant = false) {
    // this.placeSceneContainer.style.display = newValue ? null : 'none';
    if (newValue) this.placeSceneContainer.classList.remove('hideAlpha');
    else this.placeSceneContainer.classList.add('hideAlpha');
  },

  showHideUtilButtons(newValue = true, instant = false) {
    // this.utilButtons.style.display = newValue ? null : 'none';
    if (newValue) this.utilButtons.classList.remove('hideAlpha');
    else this.utilButtons.classList.add('hideAlpha');
  },

  poiInfoCloseBtnPressed() {
    window.gameManagerCurrentPoi.closePoint();
    window.splashComponentRef.playButtonSound();
  },

  showHidePoiInfo(newValue = true, instant = false, poiData = null) {
    this.poiInfoPanelOpen = newValue;
    if (newValue) {
      // Update data
      // this.poiInfoIcon.src = `./images/${poiData.poiIcon}`; // '/images' is Webpack folder name
      // this.poiInfoNumber.innerHTML = poiData.poiNumber;
      // this.poiInfoTitle.innerHTML = poiData.poiTitle;
      this.poiInfoBigHeader.innerHTML = poiData.bigHeader;
      this.poiInfoRoomIcon.src = poiData.roomIcon;
      this.poiInfoMainImage.src = poiData.mainImage;
      this.poiInfoMainImageCaption.innerHTML = poiData.mainImageCaption;
      this.poiInfoMainText.innerHTML = poiData.mainText;
      this.poiInfoBodyText.innerHTML = poiData.bodyText;
      if (this.poiInfoSection2SmallText) this.poiInfoSection2SmallText.innerHTML = poiData.section2SmallText;
      this.poiInfoSection2Title.innerHTML = poiData.section2Title;
      this.poiInfoSectionDescriptionText.innerHTML = poiData.descriptionText;

      if (this.poiInfoGalleryImages) { // Unused
        this.poiInfoGalleryImages.innerHTML = ''; // Clear all children
        for (let i = 0; i < poiData.galleryImages.length; i++) {
          const newNode = this.poiInfoGalleryImageTemplate.cloneNode(true);
          newNode.id = '';
          newNode.src = `./images/${poiData.galleryImages[i]}`; // '/images' is Webpack folder name
          newNode.classList.remove('hide');
          this.poiInfoGalleryImages.appendChild(newNode);
        }
      }

      if (this.poiInfoBulletPointsUl) { // Unused
        this.poiInfoBulletPointsUl.innerHTML = ''; // Clear all children
        for (let i = 0; i < poiData.bulletPoints.length; i++) {
          const li = document.createElement('li');
          li.innerHTML = poiData.bulletPoints[i];
          this.poiInfoBulletPointsUl.appendChild(li);
        }
      }

      this.poiInfoSeeProjectBtn.ctaLink = poiData.ctaLink;

      // Show
      this.poiInfoContainer.classList.remove('hideAlphaBottom');
      this.poiInfoCloseBtn.classList.remove('hideAlpha');
      this.poiInfoTopClose.classList.remove('hideAlpha');
      this.poiInfoContent.scrollTo(0, 0);
    }
    else {
      this.poiInfoContainer.classList.add('hideAlphaBottom');
      this.poiInfoCloseBtn.classList.add('hideAlpha');
      this.poiInfoTopClose.classList.add('hideAlpha');
    }
  },

  refreshExperienceBtnPressed() {
    window.gameManager.refreshExperience();
  },

  contactBtnPressed() {
    AnalyticsManager.sendContactClickedEvent();
    window.splashComponentRef.playButtonSound();
    // window.open('https://www.neopangea.com/contact/', '_blank');
    this.showHideForm(true);
  },

  moreInfoBtnPressed() {
    AnalyticsManager.sendCaseStudyClickedEvent(this.poiInfoSeeProjectBtn.ctaLink);
    window.splashComponentRef.playButtonSound();
    window.open(this.poiInfoSeeProjectBtn.ctaLink, '_blank');
  },

  showHideTopHeader(newValue = true) {
    if (newValue) {
      if (this.poiInfoPanelOpen) return;
      this.topHeader.classList.remove('top-header-hide');
      this.topLogo.classList.add('top-logo-move-down');
    }
    else {
      this.topHeader.classList.add('top-header-hide');
      this.topLogo.classList.remove('top-logo-move-down');
    }
  },

  showHideForm(newValue = true) {
    if (newValue) {
      this.formBox.classList.remove('hideAlphaBottom');
      this.formCloseBtn.classList.remove('hideAlpha');
      this.formBox.scrollTo(0, 0);
    }
    else {
      this.formBox.classList.add('hideAlphaBottom');
      this.formCloseBtn.classList.add('hideAlpha');
    }
    // this.showHideTopHeader(!newValue);
  },
};

export { cameraHudComponent };
